// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-panel-primary: mat-palette($mat-red);
$app-panel-accent: mat-palette($mat-indigo);

// The warn palette is optional (defaults to red).
$app-panel-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-panel-theme: mat-light-theme((
  color: (
    primary: $app-panel-primary,
    accent: $app-panel-accent,
    warn: $app-panel-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-panel-theme);
/* You can add global styles to this file, and also import other style files */

html, body {
   height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.primary-bg{
  background-color: mat-color($app-panel-primary);
}
.primary-color{
  color: mat-color($app-panel-primary);
}
.secondary-bg{
  background-color: mat-color($app-panel-accent);
}
.menu-button-bg{
  background-color: mat-color($app-panel-accent);
}
.menu-button-bg:hover{
  background-color: mat-color($app-panel-accent,300);
}
.p-color{
  color:mat-color($app-panel-primary);
}
.spacer{
  flex: 1 1 auto;
}
::-webkit-scrollbar {
  width: 8px;
}

.ab-notification .mat-dialog-container {
  padding: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mat-sidenav{
  border-right:0px;
}
.available-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px !important;
  height: 24px !important;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 18px;
  border: 2px solid green;
  text-align: center;
  color:  #fff;
  background-color: green;
  font-size: 12px;
}

/* cyan:#00BCD4
   warn:#F44336
*/

